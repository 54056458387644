// Add spacing to bootstrap variables
$spacer: 1rem;
$spacers: (
  6: (
    $spacer * 6
  )
);

@import 'variables';
@import 'bootstrap/scss/bootstrap';

@mixin transition($what: all, $time: 0.5s, $how: ease) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

.site-header {
  .navbar {
    @include transition(all, 0.5s, ease);
  }

  .navbar-brand img {
    height: 17px;
    @include media-breakpoint-up(sm) {
      height: 20px;
    }
  }

  .navbar.not-scrolled {
    box-shadow: none !important;
    background: transparent !important;
    padding-top: 3rem !important;

    .navbar-brand {
      visibility: hidden;

      img {
        height: 17px;
        @include media-breakpoint-up(sm) {
          height: 20px;
        }
      }
    }

    .navbar-toggler {
      visibility: hidden;
    }
  }
}

.jumbotron {
  background-color: $sand;
  padding-top: 10rem;
  padding-bottom: 7rem;
  @include media-breakpoint-up(md) {
    padding-top: 13rem;
    padding-bottom: 10rem;
  }
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
  margin-bottom: 0;
  border-bottom: 5px solid $secondary;
  border-radius: 0;

  h1 {
    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .lead {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }
}

.main-content {
  position: relative;
  word-break: break-word;

  ol,
  ul,
  dl {
    @include media-breakpoint-down(sm) {
      padding-inline-start: 20px;
    }
  }
}
.home {
  .card {
    height: 100%;
  }

  .main-content:before {
    content: '';
    position: absolute;
    top: -32px;
    right: 15px;
    width: 265px;
    height: 64px;
    @include media-breakpoint-up(md) {
      top: -58px;
      right: 15px;
      width: 442px;
      height: 106px;
    }
    background-image: url(/assets/images/dots.svg);
    background-size: auto;
    background-repeat: no-repeat;
    border-top: inherit;
    border-top-color: transparent;
  }

  #anwältinnen a.card-link {
    text-decoration: underline;
  }

  #anwältinnen a.card-link:hover {
    text-decoration: none;
  }

  .office-detail-1 {
    background-image: url(/assets/images/office/detail_1.jpg);
    min-height: 300px;
  }

  .office-detail-2 {
    background-image: url(/assets/images/office/detail_2.jpg);
    min-height: 300px;
  }

  .office-detail-3 {
    background-image: url(/assets/images/office/detail_3.jpg);
    min-height: 300px;
  }

  .office-detail-4 {
    background-image: url(/assets/images/office/detail_4.jpg);
    min-height: 300px;
  }
}

.page {
  padding-top: 6rem;
  padding-bottom: 3rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: 0.25rem;
}

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}
